.home {
    .title-container {
        max-width: 80%;
    }

    h1 {
        font-size: 90px;
        line-height: 1.1;
    }

    @media screen and (max-width: 1140px) {
        h1 {
            font-size: 6vw;
        }
    }

    @media screen and (max-width: 768px) {
        .title-container {
            max-width: 100%;
        }
    }
}