body {
    background-color: #F9F9F9;
    font-family: 'Rubik', sans-serif;
}

h1 {
    font-weight: 600;
}

.animated-gradient {
    position: fixed;
    z-index: -1;
    right: -40%;
    top: -20em;
    min-width: 1500px;
    opacity: 0.5;
    animation: rotate 20s linear infinite; 
}

@keyframes rotate{
    to { 
        transform: rotate(360deg); 
    }
}