header {
    margin-top: 20px;
  }
  
  .navigation {

    .nav-item {

        &.active {
            a {
                color: red;
            }
        }
        
        a {
            color: black;
            position: relative;
        }

        a {
            display: inline-block;
            padding: 0.5em 0;
            border-radius: 0;
            color: black;
            font-weight: bold;
            text-decoration: none;
            position: relative;
            &:hover{
                color: black;
                &:before{
                    transform: none;
                    opacity: 1;
                    transition-duration: .4s;
                }
                
            }

            &:before{
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                left: 0;
                bottom: -0px;
                background: lighten(black, 20%);
                transform: translateY(6px);
                opacity: 0;
                transition: 1.8s cubic-bezier(.2,1,.3,1);
                pointer-events: none;
            }
        }
    }

    .main-logo {
        font-family: 'Quicksand', sans-serif;
        font-weight: 600;
        color: black;
        font-size: 30px;

        &:hover {
            color: inherit;
        }
    }
}
  